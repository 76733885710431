import React, { createContext, useState } from 'react'

const JobsContext = createContext({
  jobs: [],
  setJobs: () => {},
})

// This context is currently use in order to retrieve list
// of jobs from API and fetch datas every 4 hours
// This context will be remove when Elastic for search job will be up

export const JobsProvider = ({ children }) => {
  const setJobs = (datas) => {
    setState((prevState) => ({
      ...prevState,
      jobs: datas,
    }))
  }

  const initState = {
    jobs: [],
    setJobs: setJobs,
  }

  const [state, setState] = useState(initState)

  return <JobsContext.Provider value={state}>{children}</JobsContext.Provider>
}

export default JobsContext
